import './App.css';

function App() {
  var _hsq = window._hsq = window._hsq || [];
  console.log(_hsq)
  _hsq.push(['setPath', '/home']);
  _hsq.push(["identify",{
    email: 'email@email.com',
    id: 'abcdefg'
}]);
  _hsq.push(['trackPageView']);
  return (
    <div className="App">
      <h1>Hey...</h1>
      <button id="button1" onClick={() => {
        var eventID = Date.now().toString() + "_" + Math.random().toString()
        var value = '100'
        window.zaraz.track('logBtnClick', {eventID, value})
        window.fbq('track', 'Contact', {value}, {eventID});
      }}>Log purchase</button>

      <button id="button2" onClick={() => {
      }}>Log out</button>

      <button id="button3" onClick={() => {
      }}>Custom event</button>

      <button id="button4" onClick={() => {
      }}>Content event</button>

      <form id="form_id" className="my_class" onSubmit={e => e.preventDefault()}>
        <input name='email' type='email'></input>
        <input name='firstname' type='text'></input>
        <input name='lastname' type='text'></input>
        <input name='phone' type='text'></input>
        <button type='submit'>Submit</button>
      </form>

      <img src="https://picsum.photos/200"></img>
    </div>
  );
}

export default App;
